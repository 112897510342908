@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-image: url("./assets/bg-light.png") !important;
  background-position: center -150px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Lora", serif;
  font-weight: 400 !important;
  font-size: 70px !important;
}

h2 {
  font-family: "Lora", serif;
  font-weight: 700 !important;
  font-size: 30px !important;
}

h3 {
  font-weight: 600 !important;
  font-size: 20px !important;
}

a {
  color: theme("colors.gray") !important;
}

a:hover {
  color: black !important;
}

hr {
  border-top-width: 0.8px !important;
  border-color: black !important;
}

hr.short-hr {
  border-top-width: 3px !important;
}

button {
  border: 0.8px solid black;
}

.circle-text {
  animation: spin 5s linear infinite;
}

.circle-text.paused {
  animation-play-state: paused;
}

.footer-menu {
  display: none;
}

.vertical-menu {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: 40px;
  right: auto;
}

@media (max-width: 800px) {
  h1 {
    font-size: 50px !important;
  }

  .footer-menu {
    display: block;
    display: flex;
    gap: 20px;
  }

  .vertical-menu {
    display: none;
  }
}

@media (max-width: 640px) {
  body {
    background-image: url("./assets/bg-light-mobile-top.png"),
      url("./assets/bg-light-mobile-bottom.png") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center top, center bottom -300px !important;
  }

  h1 {
    font-size: 40px !important;
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
